import {Image} from "react-bootstrap";

function Reference(props) {
    return (
        <a href={props.url} target="_blank" rel="noreferrer" className="ra-reference mt-5 mb-5 ms-md-5 me-md-5 mt-md-0 mb-md-0">
            <Image src={props.logo} alt={props.name} width={'240px'}></Image>
        </a>
    )
}

export default Reference;