import {Col, Container, Figure, Row} from "react-bootstrap";
import Header from "./header/Header";
import Footer from "./footer/Footer";

import TyrollerLogo from '../assets/images/tyroller_logo.png'
import RockwoolLogo from '../assets/images/rockwool_logo.png'
import Logo from '../assets/images/logo.svg'
import Reference from "./Reference";

function App() {
  return (
      <div>
          <Container fluid={true}>
              <Header/>

              <main className="text-center position-relative" tabIndex="0">
                  <section id="ra-start" className="text-center">
                          <Figure className="figure mb-5">
                              <Figure.Image src={Logo} />
                          </Figure>

                          <div className="ra-slogan mt-md-5 mb-5">Ihr Partner für Auto&shy;matisie&shy;rungs&shy;lösungen.</div>

                  </section>

                  <section id="ra-about-me">
                      <h2>Leistungen</h2>

                      <Row>
                          <Col lg={12}>
                              <ul className="ra-list mb-5 mt-3">
                                  <li><p>Projektleitung</p></li>
                                  <li><p>SPS Programmierung</p></li>
                                  <li><p>Inbetriebnahme</p></li>
                                  <li><p>Fehlersuche &amp; Optimierung</p></li>
                                  <li><p>Hardwareplanung und Schaltschrankbau mit Partner</p></li>
                              </ul>
                          </Col>
                      </Row>
                  </section>

                  <section id="ra-references">
                      <h2>Referenzen</h2>

                      <div className="d-flex justify-content-center flex-column flex-md-row align-items-center mt-md-5">
                          <Reference url="https://www.rockwool.com/de" logo={RockwoolLogo} name="Rockwool" />
                          <Reference url="https://www.tyroller.de" logo={TyrollerLogo} name="Tyroller Hydraulik" />
                      </div>

                  </section>

                  <section id="ra-contact">
                      <h2>Impressum</h2>

                      <div className="address-wrapper mt-5">
                          <div>Sebastian Ritzer Automatisierung</div>
                          <br/>
                          <div>Sebastian Ritzer</div>
                          <div>85283 Wolnzach</div>
                          <br/>
                          <div>+49 152 09404862</div>
                          <div><a href="mailto:info@ritzer-automatisierung.de">info@ritzer-automatisierung.de</a></div>
                          <br/>
                          <div>Ust-Id: DE347746679</div>
                          <div>Bildnachweis: <a href="https://shutterstock.com" rel="noreferrer" target="_blank">shutterstock.de</a></div>
                      </div>

                  </section>

              </main>

          </Container>

          <Footer/>
      </div>
  );
}

export default App;
