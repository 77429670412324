

function Footer() {
    const year = new Date().getFullYear()

    return (
        <footer className="text-center p-5">
            <p>&copy; {year} ritzer-automatisierung.de</p>
        </footer>
    )
}

export default Footer;