import {Container, Image, Navbar, Nav} from "react-bootstrap";
import logo from "../../assets/images/logo.svg";

function Header() {
    return (
        <Navbar id="ra-page-nav" bg="white" expand="lg" sticky="top" className="px-4">
            <Container fluid>
                <Navbar.Brand href="#start">
                    <Image className="header-logo" src={logo}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#ra-start">
                        <Nav.Item className="d-flex flex-row justify-content-center my-2 my-lg-0">
                            <Nav.Link href="#ra-start">Start</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="d-flex flex-row justify-content-center my-2 my-lg-0">
                            <Nav.Link href="#ra-about-me">Leistungen</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="d-flex flex-row justify-content-center my-2 my-lg-0">
                            <Nav.Link href="#ra-references">Referenzen</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="d-flex flex-row justify-content-center my-2 my-lg-0">
                            <Nav.Link href="#ra-contact">Impressum</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;